<script setup lang="ts">
import { defineModel, useAttrs } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';

import type { PropType } from 'vue';

import KogInput from 'sharedApp/components/base/input/kog-input.vue';

import '@vuepic/vue-datepicker/dist/main.css';

defineOptions({
  inheritAttrs: false,
});

defineProps({
  label: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  startDate: {
    type: [String, Date],
    default: null,
    validator(value: string | Date | null) {
      if (value === null) {
        return true;
      }

      return !Number.isNaN(Date.parse(value.toString()));
    },
  },
  minDate: {
    type: [String, Date],
    default: null,
    validator(value: string | Date | null) {
      if (value === null) {
        return true;
      }

      return !Number.isNaN(Date.parse(value.toString()));
    },
  },
  maxDate: {
    type: [String, Date],
    default: null,
    validator(value: string | Date | null) {
      if (value === null) {
        return true;
      }

      return !Number.isNaN(Date.parse(value.toString()));
    },
  },
  utc: {
    type: Boolean,
    default: true,
  },
  /** Formatting uses date-fns */
  format: {
    type: [String, Function] as PropType<string | ((date: Date) => string)>,
    default: 'yyyy-MM-dd HH:mm',
  },
  enableTime: {
    type: Boolean,
    default: true,
  },
  bottomInfo: {
    type: String,
    default: '',
  },
  /**
   * Specifies whether the datepicker should be a range picker.
   */
  range: {
    type: Boolean,
    default: false,
  },
  /**
   * Use teleport to position the datepicker menu.
   * This is useful if you have hidden overflow on the parent HTML element where
   * the menu is not showing in full. If you just set the value to true, the menu
   * will be placed on body.
   */
  teleport: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['change', 'closed']);

const date = defineModel<null | Date | Date[] | string | string[]>('date', { required: true });

const attrs = useAttrs();

function onPickerMenuClosed() {
  emit('closed', date.value ? date.value.toString() : date.value);
}
</script>

<template>
  <vue-date-picker
    v-model="date"
    model-type="format"
    auto-apply
    arrow-navigation
    :range="range"
    :enable-time-picker="enableTime"
    time-picker-inline
    position="left"
    :clearable="false"
    :utc="utc"
    :format="format"
    :action-row="{
      showSelect: false,
      showCancel: false,
      showNow: false,
      showPreview: false,
    }"
    :start-date="startDate"
    :min-date="minDate"
    :max-date="maxDate"
    :teleport="teleport"
    @closed="onPickerMenuClosed"
  >
    <template #dp-input="{ value, onInput, onBlur }">
      <kog-input
        v-bind="attrs"
        :value="value"
        :label="label"
        :placeholder="placeholder"
        :helper-text="bottomInfo"
        :is-label-hidden="true"
        input-icon="calendar"
        readonly
        @keydown.enter="onInput"
        @blur="onBlur"
      />
    </template>
  </vue-date-picker>
</template>

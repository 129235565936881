import type { App } from 'vue';

import { getTerm } from 'sharedApp/services/educationSystem/education-system-service.js';

export const EDUCATION_SYSTEMS = ['NGSS', 'IBDP', 'IGCSE'] as const;
type EducationSystem = (typeof EDUCATION_SYSTEMS)[number];

const EducationSystemTermPlugin = {
  install(app: App) {
    let educationSystem: EducationSystem;

    // eslint-disable-next-line no-underscore-dangle
    const _term: {
      (key: string): string;
      setEducationSystem: (edSystem: EducationSystem) => void;
    } = function _term(key: string): string {
      const term = getTerm(key, educationSystem);
      if (!term) {
        // eslint-disable-next-line no-console
        console.warn(`Cannot find term for '${key}'`);
        return key;
      }
      return term;
    };

    _term.setEducationSystem = (edSystem: EducationSystem): void => {
      educationSystem = edSystem;
    };

    /* Plugin Composition API */
    app.provide('term', _term);

    /* Plugin Options API */
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$term = _term;
  },
};

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $term: {
      (key: string): string;
      setEducationSystem: (edSystem: EducationSystem) => void;
    };
  }
}

export default EducationSystemTermPlugin;

import Rox from 'rox-browser';

import type { User } from 'learning/common/api/user.types.ts';
import type { Data } from 'sharedApp/types.ts';
import type { PartialDeep } from 'type-fest';

import DjangoSettings from 'sharedApp/django-settings-module.ts';

let roxSetupPromise: Promise<unknown> | null = null;

function getAuthKey() {
  const key = DjangoSettings.ROLLOUT.AUTH_KEY;
  if (typeof key === 'string' && key.length > 0) {
    return key;
  }

  throw new Error('ROLLOUT.AUTH_KEY is not set');
}

function setupRoxSdk() {
  if (roxSetupPromise) {
    return roxSetupPromise;
  }
  try {
    const authKey = getAuthKey();
    roxSetupPromise = Rox.setup(authKey, {});
  } catch (error: unknown) {
    console.warn('Failed to setup Rollout SDK', error); // eslint-disable-line no-console
    roxSetupPromise = Promise.resolve();
  }

  return roxSetupPromise;
}

export function featureFlags() {
  async function isFeatureEnabled<C = Data>(
    featureName: string,
    context: { user: PartialDeep<User> } & C,
    defaultValue = false,
  ) {
    try {
      getAuthKey();
    } catch {
      console.warn('ROLLOUT.AUTH_KEY is not set, returning default value'); // eslint-disable-line no-console
      return defaultValue;
    }

    await setupRoxSdk();
    const flatContext = {
      school_id: context.user.school?.id,
      school_id_str: `${context.user.school?.id}`,
      user_id: context.user.id,
      user_id_str: `${context.user.id}`,
      ...context,
    };
    return Rox.dynamicApi.isEnabled(featureName, defaultValue, flatContext);
  }

  return {
    init: setupRoxSdk,
    isFeatureEnabled,
  };
}

import { IntegrationSlugEnum } from '@apis/generated/models.ts';
import { AccountsService } from '@apis/generated/services.ts';

const namespaced = true;

const mutations = {
  setIsGoogleClassroomTeacher(state, isGoogleClassroomTeacher) {
    state.userProfile.isGoogleClassroomTeacher = isGoogleClassroomTeacher;
  },
  setShowTeacherOverviewWelcomeMessage(state, show) {
    state.userProfile.showTeacherOverviewWelcomeMessage = show;
  },
};

const actions = {
  async getUserProfile({ commit }) {
    const response = await AccountsService.accountsUserProfileRetrieve();
    const {
      integration_slugs: integrationSlugs,
      show_teacher_overview_welcome_message: showTeacherOverviewWelcomeMessage,
    } = response.user_profile;
    const isGoogleClassroomTeacher = integrationSlugs.includes(
      IntegrationSlugEnum.GOOGLE_CLASSROOM,
    );
    commit('setIsGoogleClassroomTeacher', isGoogleClassroomTeacher);
    commit('setShowTeacherOverviewWelcomeMessage', showTeacherOverviewWelcomeMessage);
    return true;
  },
  async updateShowTeacherOverviewWelcomeMessage({ commit }, show) {
    const data = {
      requestBody: {
        show_teacher_overview_welcome_message: show,
      },
    };
    await AccountsService.accountsMeNotificationSettingsPartialUpdate(data);
    commit('setShowTeacherOverviewWelcomeMessage', show);
    return true;
  },
};

const state = {
  userProfile: {
    isGoogleClassroomTeacher: false,
    showTeacherOverviewWelcomeMessage: false,
  },
};

export default {
  namespaced,
  actions,
  state,
  mutations,
};

import { compile } from 'path-to-regexp';
import qs from 'qs';

import { PRACTICE_OCCASION_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

const subjectClassBaseUrl = '/api/schoolstaff/subject_class/:subjectClassId/';

const SCHOOLSTAFF_API_URLS = {
  'subjectclass-details': compile(subjectClassBaseUrl),
  'subjectclass-book-progress': compile(`${subjectClassBaseUrl}book_progress/`),
  'subjectclass-students-list': compile(`${subjectClassBaseUrl}students/`),
  'subjectclass-teacher-association': compile(`${subjectClassBaseUrl}school_teachers/`),
  'subjectclass-teacher-details': compile(`${subjectClassBaseUrl}teacher_users/:userId/`),
  'subjectclass-teacher-users': compile(`${subjectClassBaseUrl}teacher_users/`),
};

const API_URLS = {
  'subject-class-lms-integration-categories': compile(
    '/api/subject-classes/:subjectClassId/lms-integrations/:integrationSlug/categories/',
  ),
};

const subjectClassesApiUrl = '/api/subject-classes/:subjectClassId/';
const subjectClassStudentPracticeItemsPath = compile(
  `${subjectClassesApiUrl}user/:userId/practice_items/`,
);
const subjectClassStudentBattlesPath = compile(
  `${subjectClassesApiUrl}users/:userId/strength-battles/`,
);
const subjectClassStudentStrengthTestsPath = compile(
  `${subjectClassesApiUrl}students/:userId/strength_tests/`,
);
const subjectClassQuestionProgressPath = compile(`${subjectClassesApiUrl}practice_items/`);
const subjectClassReflectionQuestionProgressPath = compile(
  `${subjectClassesApiUrl}reflection-question-practice-items/`,
);

const QUESTION_INSIGHTS_DEFAULT_OCCASION_TYPES = [
  PRACTICE_OCCASION_TYPES.EXERCISE,
  PRACTICE_OCCASION_TYPES.ASSIGNMENT,
  PRACTICE_OCCASION_TYPES.STRENGTH_BATTLE,
  PRACTICE_OCCASION_TYPES.STRENGTH_TEST,
];

export async function fetchSubjectClassBookProgress(subjectClassId, params = {}) {
  const url = SCHOOLSTAFF_API_URLS['subjectclass-book-progress']({ subjectClassId });
  const result = await axios.get(url, {
    params,
  });
  return result.data;
}

export async function fetchStudentsList(subjectClassId) {
  const url = SCHOOLSTAFF_API_URLS['subjectclass-students-list']({ subjectClassId });
  const result = await axios.get(url, { cache: true });
  return result.data;
}

export async function getClassTeachers(subjectClassId, params, useCache = true) {
  const url = SCHOOLSTAFF_API_URLS['subjectclass-teacher-users']({ subjectClassId });
  const result = await axios.get(url, {
    params: {
      ...params,
    },
    cache: useCache,
  });
  return result.data;
}

export async function removeClassTeacher(subjectClassId, teacherId) {
  const url = SCHOOLSTAFF_API_URLS['subjectclass-teacher-details']({
    subjectClassId,
    userId: teacherId,
  });
  const result = await axios.delete(url);
  return result.data;
}

export async function addClassTeachers(subjectClassId, teacherIds) {
  const url = SCHOOLSTAFF_API_URLS['subjectclass-teacher-association']({ subjectClassId });
  const result = await axios.put(url, {
    teacher_ids: teacherIds,
  });
  return result.data;
}

export async function fetchSubjectClass(subjectClassId, useCache = true) {
  const url = SCHOOLSTAFF_API_URLS['subjectclass-details']({ subjectClassId });
  const result = await axios.get(url, { cache: useCache });
  return result.data;
}

export async function fetchQuestionProgress(subjectClassId, params = {}) {
  const url = subjectClassQuestionProgressPath({ subjectClassId });
  const result = await axios.get(url, {
    cache: true,
    params: {
      ...params,
      occasion_type: params.occasion_type ?? QUESTION_INSIGHTS_DEFAULT_OCCASION_TYPES,
    },
    paramsSerializer: parameters => qs.stringify(parameters, { arrayFormat: 'repeat' }),
  });
  return result.data;
}

export async function fetchStudentPracticeItems(subjectClassId, userId, params = {}) {
  const url = subjectClassStudentPracticeItemsPath({ subjectClassId, userId });
  const result = await axios.get(url, {
    cache: true,
    params: {
      ...params,
      occasion_type: params.occasion_type ?? QUESTION_INSIGHTS_DEFAULT_OCCASION_TYPES,
    },
    paramsSerializer: parameters => qs.stringify(parameters, { arrayFormat: 'repeat' }),
  });
  return result.data;
}

export async function fetchReflectionQuestionProgress(
  subjectClassId,
  useCache = true,
  params = {},
) {
  const url = subjectClassReflectionQuestionProgressPath({ subjectClassId });
  const result = await axios.get(url, {
    cache: useCache,
    params,
  });
  return result.data;
}

export async function fetchSubjectClassStudentBattles({ subjectClassId, userId, params }) {
  const url = subjectClassStudentBattlesPath({ subjectClassId, userId });
  const response = await axios.get(url, { params });
  return response.data;
}

export async function fetchSubjectClassStudentStrengthTests({ subjectClassId, userId, params }) {
  const url = subjectClassStudentStrengthTestsPath({ subjectClassId, userId });
  const response = await axios.get(url, { params });
  return response.data;
}

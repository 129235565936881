import mixpanel from 'mixpanel-browser';

import { trackMixpanelEventViaBackend } from '@apis/mixpanel.ts';

type TrackingEvent = {
  eventName: string;
  eventProperties: Record<string, unknown>;
};
export type MixpanelUserConstants = {
  User: {
    email: string;
    first_name: string;
    last_name: string;
    id: number;
    created?: string;
  };
};

type MixpanelModule = {
  authToken: string | null;
  userConstants: MixpanelUserConstants | null;
  eventQueue: TrackingEvent[];
  backendEventQueue: TrackingEvent[];
  defaultProperties: TrackingEvent['eventProperties'];
  waitForDefaultProperties: boolean;
  areDefaultPropertiesSet: boolean;

  isMixPanelEnabled(): boolean;
  areDefaultPropertiesReady(): boolean;
  setWaitForDefaultProperties(): void;
  addEventToQueue(event: TrackingEvent): void;
  addEventToBackendQueue(event: TrackingEvent): void;
  handleQueuedEvents(): void;
  trackEvent(eventName: string, eventProperties: TrackingEvent['eventProperties']): void;
  trackEventViaBackend(
    eventName: string,
    eventProperties: TrackingEvent['eventProperties'],
  ): Promise<void>;
  sendBackendEvent(
    eventName: string,
    eventProperties: TrackingEvent['eventProperties'],
  ): Promise<void>;
  incrementCounterProperty(propertyName: string): void;
  registerCounterProperty(propertyName: string): void;
  resetDefaultPropertiesForEvents(): void;
  registerDefaultPropertiesForEvents(properties: TrackingEvent['eventProperties']): void;
  getDefaultPropertiesForEvents(
    includeMixpanelPersistentProperties: boolean,
  ): Record<string, unknown>;
  formatDate(isoFormatDateAsString: string): string;
  setupPeopleTracking(additionalPeopleProperties: Record<string, unknown>): void;
  init(authToken: string | null, userConstants: MixpanelUserConstants): void;
  trackLinks(
    element: HTMLElement,
    eventName: string,
    eventProperties: Record<string, unknown>,
  ): void;
};

const mixpanelModule: MixpanelModule = {
  authToken: null,
  userConstants: null,
  eventQueue: [],
  backendEventQueue: [],
  defaultProperties: {},
  waitForDefaultProperties: false,
  areDefaultPropertiesSet: false,

  isMixPanelEnabled() {
    return !!mixpanelModule.authToken;
  },

  areDefaultPropertiesReady() {
    return mixpanelModule.waitForDefaultProperties ? mixpanelModule.areDefaultPropertiesSet : true;
  },

  setWaitForDefaultProperties() {
    mixpanelModule.waitForDefaultProperties = true;
    mixpanelModule.areDefaultPropertiesSet = false;
  },

  addEventToQueue(event) {
    mixpanelModule.eventQueue.push(event);
  },

  addEventToBackendQueue(event) {
    mixpanelModule.backendEventQueue.push(event);
  },

  handleQueuedEvents() {
    const defaultProperties = mixpanelModule.getDefaultPropertiesForEvents(false);
    mixpanelModule.eventQueue.forEach(event => {
      mixpanel.track(event.eventName, { ...defaultProperties, ...event.eventProperties });
    });
    mixpanelModule.eventQueue = [];

    mixpanelModule.backendEventQueue.forEach(event => {
      mixpanelModule.sendBackendEvent(event.eventName, {
        ...defaultProperties,
        ...event.eventProperties,
      });
    });
    mixpanelModule.backendEventQueue = [];
  },

  trackEvent(eventName, eventProperties) {
    if (mixpanelModule.isMixPanelEnabled() && mixpanelModule.areDefaultPropertiesReady()) {
      const defaultProperties = mixpanelModule.getDefaultPropertiesForEvents(false);
      mixpanel.track(eventName, {
        ...defaultProperties,
        ...eventProperties,
      });
    } else {
      mixpanelModule.addEventToQueue({ eventName, eventProperties });
    }
  },

  async trackEventViaBackend(eventName, eventProperties) {
    if (mixpanelModule.areDefaultPropertiesReady()) {
      await mixpanelModule.sendBackendEvent(eventName, eventProperties);
    } else {
      mixpanelModule.addEventToBackendQueue({ eventName, eventProperties });
    }
  },

  sendBackendEvent(eventName, eventProperties) {
    const defaultProperties = mixpanelModule.getDefaultPropertiesForEvents(true);
    return trackMixpanelEventViaBackend(eventName, {
      ...defaultProperties,
      ...eventProperties,
    });
  },

  incrementCounterProperty(propertyName) {
    if (mixpanelModule.isMixPanelEnabled()) {
      const value = mixpanel.get_property(propertyName);
      const update: Record<string, number> = {};
      if (value && typeof value === 'number') {
        update[propertyName] = value + 1;
      } else {
        update[propertyName] = 1;
      }
      mixpanel.register(update);
    }
  },

  registerCounterProperty(propertyName) {
    if (mixpanelModule.isMixPanelEnabled()) {
      const value = mixpanel.get_property(propertyName);
      mixpanel.register({ propertyName: value });
    }
  },

  resetDefaultPropertiesForEvents() {
    mixpanelModule.defaultProperties = {};
    mixpanelModule.areDefaultPropertiesSet = false;
  },

  registerDefaultPropertiesForEvents(properties) {
    mixpanelModule.defaultProperties = {
      ...mixpanelModule.defaultProperties,
      ...properties,
    };
    mixpanelModule.areDefaultPropertiesSet = true;
    if (mixpanelModule.waitForDefaultProperties && mixpanelModule.isMixPanelEnabled()) {
      mixpanelModule.handleQueuedEvents();
    }
  },

  getDefaultPropertiesForEvents(includeMixpanelPersistentProperties) {
    let mixpanelProperties = {};
    if (mixpanelModule.isMixPanelEnabled() && includeMixpanelPersistentProperties) {
      // @ts-expect-error - Types does not include persistence but exists in lib
      // https://github.com/mixpanel/mixpanel-js/issues/50
      mixpanelProperties = mixpanel.persistence.properties();
    }
    return {
      ...mixpanelProperties,
      ...mixpanelModule.defaultProperties,
    };
  },

  formatDate(isoFormatDateAsString) {
    const createdDate = new Date(isoFormatDateAsString);
    return createdDate.toISOString();
  },

  setupPeopleTracking(additionalPeopleProperties) {
    if (!mixpanelModule.isMixPanelEnabled() || !mixpanelModule.userConstants?.User) {
      return;
    }
    const user = mixpanelModule.userConstants.User;
    mixpanel.identify(user.id.toString());
    const peopleProperties = {
      $email: user.email,
      name: `${user.first_name} ${user.last_name}`,
      user_id: user.id,
      account_created: user.created ? mixpanelModule.formatDate(user.created) : '',
    };

    const mergedProperties = Object.assign(peopleProperties, additionalPeopleProperties);
    mixpanel.people.set(mergedProperties);
  },

  init(authToken, userConstants) {
    if (authToken) {
      mixpanelModule.authToken = authToken;
      mixpanelModule.userConstants = userConstants;
      mixpanelModule.resetDefaultPropertiesForEvents();
      mixpanel.init(authToken, {
        cookie_expiration: 30,
      });
      mixpanel.identify(userConstants.User.id.toString());
      mixpanelModule.handleQueuedEvents();
    }
  },

  trackLinks(element, eventName, eventProperties) {
    if (mixpanelModule.isMixPanelEnabled()) {
      mixpanel.track_links(element, eventName, eventProperties);
    }
  },
};

export default mixpanelModule;

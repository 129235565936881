import * as quizApi from '@apis/quiz.js';

export const isResponseCorrect = response => {
  return (
    response.user_response_correctness &&
    Object.values(response.user_response_correctness).every(item => item)
  );
};

export default {
  namespaced: true,
  state: {
    quiz: {},
    quizOccasion: {},
    quizQuestionResponses: {},
    studentOccasions: [],
    studentResponses: [],
    solutions: {},
    explanations: {},
  },
  getters: {
    getQuestionResponseByQuestionId(state) {
      return questionUuid => state.quizQuestionResponses[questionUuid];
    },
    questionsAnsweredCorrectlyCount(state) {
      return Object.values(state.quizQuestionResponses).filter(isResponseCorrect).length;
    },
  },
  mutations: {
    setQuiz(state, quiz) {
      state.quiz = quiz;
    },
    setQuizOccasion(state, quizOccasion) {
      state.quizOccasion = quizOccasion;
    },
    setSolution(state, { questionUuid, solution, explanation }) {
      state.solutions[questionUuid] = solution;
      state.explanations[questionUuid] = explanation;
    },
    addQuizQuestionResponse(state, { quizQuestionResponse, questionUuid }) {
      state.quizQuestionResponses[questionUuid] = quizQuestionResponse;
    },
    setStudentOccasions(state, studentOccasions) {
      state.studentOccasions = studentOccasions;
    },
    setStudentResponses(state, studentResponses) {
      state.studentResponses = studentResponses;
    },
    setQuizQuestionResponses(state, quizQuestionResponses) {
      state.quizQuestionResponses = quizQuestionResponses;
    },
    resetQuizOccasion(state) {
      state.quizOccasion = {};
      state.quizQuestionResponses = {};
    },
  },
  actions: {
    async fetchQuiz({ commit }, quizId) {
      commit('setQuiz', await quizApi.fetchQuiz(quizId));
    },
    async createQuizOccasion({ commit }, quizId) {
      commit('setQuizOccasion', await quizApi.createQuizOccasion(quizId));
    },
    async submitQuestionOccasion({ commit }, { quizId, occasionId }) {
      const isSubmitted = true;
      const quizOccasion = await quizApi.updateQuizOccasion(quizId, occasionId, isSubmitted);
      commit('setQuizOccasion', quizOccasion);
    },
    async createQuizQuestionResponse(
      { commit },
      { quizId, occasionId, responseType, questionUuid, userResponse, subjectClassId },
    ) {
      const quizQuestionResponse = await quizApi.createQuizQuestionResponse(
        quizId,
        occasionId,
        responseType,
        questionUuid,
        userResponse,
      );
      const solutions = await quizApi.fetchQuestionSolution(quizId, occasionId, subjectClassId, [
        questionUuid,
      ]);
      commit('addQuizQuestionResponse', { quizQuestionResponse, questionUuid });
      solutions.forEach(solution => {
        commit('setSolution', {
          questionUuid: solution.question_uuid,
          solution: solution.solution,
          explanation: solution.explanation,
        });
      });
      return quizQuestionResponse;
    },
    async fetchStudentOccasions({ commit }, { quizId, classId = undefined }) {
      const studentOccasions = await quizApi.fetchQuizOccassionList(quizId, classId);
      commit('setStudentOccasions', studentOccasions);
    },
    async fetchStudentResponses({ commit }, { quizId, classId = undefined }) {
      const studentResponses = await quizApi.fetchQuizResponsesList(quizId, classId);
      commit('setStudentResponses', studentResponses);
    },
    async fetchLastQuizOccasion({ commit }, { quizId, isSubmitted, classId = undefined }) {
      const occasions = await quizApi.fetchQuizOccasions(quizId, isSubmitted, classId);
      if (occasions.length > 0) {
        const latestOccasion = occasions[0];
        const quizResponses = await quizApi.fetchQuizQuestionResponses(
          quizId,
          latestOccasion.id,
          classId,
        );
        commit('setQuizOccasion', latestOccasion);
        commit('setQuizQuestionResponses', quizResponses);
      } else {
        commit('resetQuizOccasion');
      }
    },
  },
};

import type { MixpanelUserConstants } from 'sharedApp/libs/mixpanel.ts';
import type { App, InjectionKey } from 'vue';

import mixpanelModule from 'sharedApp/libs/mixpanel.ts';

type MixpanelPluginOptions = {
  authToken: string;
  userConstants: MixpanelUserConstants;
};

const mixpanel = {
  trackEventViaBackend: mixpanelModule.trackEventViaBackend,
  trackEvent: mixpanelModule.trackEvent,
  trackLinks: mixpanelModule.trackLinks,
  setWaitForDefaultProperties: mixpanelModule.setWaitForDefaultProperties,
  registerDefaultPropertiesForEvents: mixpanelModule.registerDefaultPropertiesForEvents,
  setupUserProperties: mixpanelModule.setupPeopleTracking,
  incrementCounterProperty: mixpanelModule.incrementCounterProperty,
  registerCounterProperty: mixpanelModule.registerCounterProperty,
  init: mixpanelModule.init,
  resetDefaultPropertiesForEvents: mixpanelModule.resetDefaultPropertiesForEvents,
};

type MixpanelPlugin = typeof mixpanel;

export const MixpanelPluginInjectionKey: InjectionKey<MixpanelPlugin> = Symbol('mixpanel');
const MixpanelPlugin = {
  install(app: App, options: MixpanelPluginOptions) {
    if (options) {
      mixpanelModule.init(options.authToken, options.userConstants);
    }

    /* Plugin Composition API */
    app.provide(MixpanelPluginInjectionKey, mixpanel);

    /* Plugin Options API */
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$mixpanel = mixpanel;
  },
};

export default MixpanelPlugin;

import { capitalize } from 'vue';
import { compile } from 'path-to-regexp';
import qs from 'qs';

import { QuestionsService } from '@apis/generated';

import { QUESTION_TYPES } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

const schoolStaffBaseUrl = '/api/schoolstaff/';
const subjectQuestionBaseUrl = `${schoolStaffBaseUrl}question/:subjectId/`;
const SCHOOLSTAFF_API_URLS = {
  'mcq-detail': compile(`${subjectQuestionBaseUrl}mcq/:questionId/`),
  'mcq-list': compile(`${subjectQuestionBaseUrl}mcq/`),
  'stq-detail': compile(`${subjectQuestionBaseUrl}stq/:questionId/`),
  'stq-list': compile(`${subjectQuestionBaseUrl}stq/`),
  'ltq-detail': compile(`${subjectQuestionBaseUrl}ltq/:questionId/`),
  'ltq-list': compile(`${subjectQuestionBaseUrl}ltq/`),
  'subject-exam-style-questions': compile(
    `${schoolStaffBaseUrl}subjects/:subjectId/exam_style_questions_by_topic/`,
  ),
  'exam-style-questions-list-view': compile(
    `${schoolStaffBaseUrl}subjects/:subjectId/exam_style_questions/`,
  ),
};
const questionsUrl = '/api/questions/';
const questionUrl = compile([questionsUrl, ':id/'].join(''));

export async function getExerciseQuestions({
  subjectNodeIds,
  shuffleSeed,
  showAnswers = false,
  detail = false,
  active = true,
}) {
  const response = await axios.get(questionsUrl, {
    params: {
      feature_exercise_question: 'True',
      subjectnode: subjectNodeIds,
      active: typeof active === 'boolean' ? capitalize(active.toString()) : undefined,
      all: 'True',
      showAnswers: showAnswers ? 'True' : 'False',
      detail: detail ? 'True' : 'False',
      shuffle_seed: shuffleSeed,
    },
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return response.data;
}

export async function getExerciseQuestionsFromService({
  subjectNodeIds,
  shuffleSeed,
  active,
  showAnswers = false,
}) {
  const queryParams = {
    feature: 'exercise',
    subject_node: subjectNodeIds,
    active: typeof active === 'boolean' ? capitalize(active.toString()) : undefined,
    paginate: 'False',
    include_solution: showAnswers ? 'True' : 'False',
    shuffle_seed: shuffleSeed,
  };
  const result = await QuestionsService.questionsListRetrieve(queryParams);
  return result;
}

export async function getSectionQuestionsFromService({
  subjectNodeIds,
  showAnswers = false,
  active = true,
  all = true,
  ordering = undefined,
  level = undefined,
  cache = false,
}) {
  const queryParams = {
    feature: 'section',
    subject_nodes: subjectNodeIds,
    paginate: !all,
    include_solution: showAnswers,
    ordering,
    level,
    active,
  };
  const result = await QuestionsService.questionsListRetrieve(queryParams);
  return result;
}

export async function fetchExamStyleQuestionsByTopic(subjectId, params = {}) {
  const url = SCHOOLSTAFF_API_URLS['subject-exam-style-questions']({ subjectId });
  const response = await axios.get(url, { params });
  return response.data;
}

export async function fetchExamStyleQuestions(subjectId, params = {}) {
  const url = SCHOOLSTAFF_API_URLS['exam-style-questions-list-view']({ subjectId });
  const response = await axios.get(url, { params });
  return response.data;
}

export async function getQuestionsById(subjectNodeIds, ids, params) {
  const response = await axios.get(questionsUrl, {
    params: {
      id: ids,
      subjectnode: subjectNodeIds,
      ...params,
    },
    paramsSerializer: param => qs.stringify(param, { arrayFormat: 'repeat' }),
  });

  return response.data;
}

export async function fetchReflectionQuestions(subjectNodeId) {
  const params = {
    type: 'ltq',
    feature_exercise_question: 'True',
    subjectnode: subjectNodeId,
    ordering: 'created',
    active: 'True',
    all: 'True',
    reflection_questions_in_content: 'True',
  };
  const response = await axios.get(questionsUrl, { params });
  return response.data;
}

export async function getSectionQuestions(subjectNodeIds, options = {}) {
  const additionalParams = options.additionalParams || {};
  const cache = options.cache || false;
  const response = await axios.get(questionsUrl, {
    cache,
    params: {
      type: ['stq', 'ftb', 'mcq'],
      feature_section_question: 'True',
      subjectnode: subjectNodeIds,
      all: 'True',
      ...additionalParams,
    },
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return response.data;
}

export async function getQuestion(id, type = null) {
  const response = await axios.get(questionUrl({ id }), { params: { type } });
  return response.data;
}

function getQuestionTypeUrl(questionType, apiType) {
  switch (questionType) {
    case QUESTION_TYPES.MCQ:
      return SCHOOLSTAFF_API_URLS[`mcq-${apiType}`];
    case QUESTION_TYPES.STQ:
      return SCHOOLSTAFF_API_URLS[`stq-${apiType}`];
    case QUESTION_TYPES.LTQ:
      return SCHOOLSTAFF_API_URLS[`ltq-${apiType}`];
    default:
      throw new Error(`Invalid question type: ${questionType}`);
  }
}

export async function createTeacherQuestion(subjectId, data, questionType) {
  const urlFunction = getQuestionTypeUrl(questionType, 'list');
  const url = urlFunction({ subjectId });
  const response = await axios.post(url, data);
  return response.data;
}

export async function editTeacherQuestion(subjectId, questionId, data, questionType) {
  const urlFunction = getQuestionTypeUrl(questionType, 'detail');
  const url = urlFunction({ subjectId, questionId });
  const response = await axios.put(url, data);
  return response.data;
}

export async function deleteTeacherQuestion(subjectId, questionId, questionType) {
  const urlFunction = getQuestionTypeUrl(questionType, 'detail');
  const url = urlFunction({ subjectId, questionId });
  const response = await axios.delete(url);
  return response.data;
}

import { AccountsService } from '@apis/generated/services.ts';

import { AnonymousUser, User } from 'learning/common/api/user.js';

const namespaced = true;

const mutations = {
  setUser(state, userObj) {
    state.user = userObj;
  },
};

const getters = {
  hasUser(state) {
    return !state.user.isAnonymous();
  },
  user(state) {
    return state.user;
  },
  isDemoUser: () => !!window.KOG.USER_CONSTANTS.User.school?.is_demo,
  demoType: () => window.KOG.USER_CONSTANTS.User.school?.demo_type,
  isNGLSchool: () => {
    const { school } = window.KOG.USER_CONSTANTS.User;
    if (!school) {
      return false;
    }
    const schoolSuffix = school.name.split(' ').pop();
    return schoolSuffix === 'NGL';
  },
  enableTrialCTA: (state, getters_) => {
    return getters_.isDemoUser && !getters_.isNGLSchool;
  },
};

const actions = {
  async fetchUser({ commit, state }) {
    if (state.user.id) {
      return state.user;
    }
    const user = new User(await AccountsService.accountsMeRetrieve());
    commit('setUser', user);
    return state.user;
  },
  async updateMe({ commit }, user) {
    const updatedUser = new User(await AccountsService.accountsMeUpdate(user));
    commit('setUser', updatedUser);
  },
};

const state = {
  user: new AnonymousUser(),
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};

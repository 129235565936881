import 'sharedApp/polyfills.js';

import { createApp } from 'vue';
import { createHead } from '@unhead/vue';
import FloatingVue from 'floating-vue';
import { createPinia } from 'pinia';
import PortalVue from 'portal-vue';
import { createVueWait } from 'vue-wait';
import { Vue3Mq } from 'vue3-mq';

import themes from 'sharedApp/components/tooltip/themes.ts';
import {
  VIEWPORT_L_PX,
  VIEWPORT_M_PX,
  VIEWPORT_S_PX,
  VIEWPORT_XL_PX,
  VIEWPORT_XS_PX,
} from 'sharedApp/const/css/media.js';
import KogDirectives from 'sharedApp/directives/kog-directives.js';
import SentryInit from 'sharedApp/libs/sentry-vue.js';
import ModalPlugin from 'sharedApp/plugins/modal/modal-plugin.ts';
import EducationSystemTermPlugin from 'sharedApp/plugins/term/education-system-term-plugin.ts';
import ToasterPlugin from 'sharedApp/plugins/toaster/toaster-plugin.ts';
import EventPlugin from 'sharedApp/plugins/tracking/event/event-plugin.js';
import MixpanelPlugin from 'sharedApp/plugins/tracking/mixpanel/mixpanel-plugin.ts';
import FeatureFlagsPlugin from 'sharedApp/services/featureflags/feature-flags-plugin.js';
import App from 'studyApp/app.vue';
import router from 'studyApp/router/index.js';
import store from 'studyApp/store/index.js';

const VueWait = createVueWait({
  useVuex: true,
  registerDirective: false,
});
const pinia = createPinia();

const head = createHead();

const app = createApp(App);
app.use(KogDirectives);
app.use(store);
app.use(router);
app.use(pinia);
app.use(head);
app.use(ModalPlugin);
app.use(ToasterPlugin);
app.use(VueWait);
app.use(FeatureFlagsPlugin);
app.use(EducationSystemTermPlugin);
app.use(PortalVue);
app.use(MixpanelPlugin);
app.use(FloatingVue, { themes });
app.use(EventPlugin);
app.use(Vue3Mq, {
  breakpoints: {
    xs: VIEWPORT_XS_PX,
    s: VIEWPORT_S_PX,
    m: VIEWPORT_M_PX,
    l: VIEWPORT_L_PX,
    xl: VIEWPORT_XL_PX,
  },
});

app.provide('wait', app.config.globalProperties.$wait);

SentryInit(app, router);

router.isReady().then(() => {
  app.mount('#app');
});
